import { Component, OnInit } from '@angular/core';
import { EmployeeModel } from '../../models/employee.model';
import { HttpService } from '../../shared/http.service';
import { AuthService } from '../../shared/auth.service';
import { HotelModel } from '../../models/hotel.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hotel-display',
  templateUrl: './property-display.component.html',
  styleUrls: ['./property-display.component.css']
})
export class PropertyDisplayComponent implements OnInit {
  employee: EmployeeModel = new EmployeeModel();
  hotels: HotelModel[] = [];
  loading = true;
  searchKey;
  allHotels: HotelModel[];
  processing= false;
  showAlert; alertMessage; showHotelNum = false;

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getEmployeeDetails();
  }

  logOut() {
    this.authService.logOut();
  }

  getEmployeeDetails() {
    const employeeId = localStorage.getItem('employeeId');
    this.httpService.getEmployeeDetails(`employees/${employeeId}`).subscribe(
      res => {
        this.employee = res;
       if(this.searchKey) {
         this.getEmployeeHotels(`hotels?email=${res.emailAddress}&filterHotel=${(this.searchKey)}`);
       } else  {
         this.getEmployeeHotels(`hotels?email=${res.emailAddress}`);
       }
      },
      err => {
        console.log(err);
      }
    );
  }
  filterHotel($event) {
    this.showHotelNum = true;
    this.searchKey = $event.target.value;
    // this.hotels = this.allHotels.filter((e) => e.hotelName.toLowerCase()?.indexOf(this.searchKey.toLowerCase()) > -1);

  }
  handleSearchHotels() {
    this.processing = true
    // filterHotel=${this.searchKey}
    // console.log('this.searchKey', this.searchKey);
    this.getEmployeeDetails();
  }

  getEmployeeHotels(path) {
    this.loading = true
    this.processing = true
    this.httpService.getHotels(path).subscribe(
      res => {
        this.hotels = res;
        this.allHotels = res;
        if(res.length === 0) {
          this.alertMessage = `No Hotel Found`;
          this.showAlert = true;
        }
        this.loading = false;
        this.processing = false;
      },
      err => {
        console.log(err);
      }
    );
  }
  onAlertDismiss() {
    this.showAlert = false;
  }
  proceedToDashboard(hotel) {
    localStorage.setItem('hotelId', hotel.hotelId);
    sessionStorage.setItem('hotelName', hotel.hotelName);
    sessionStorage.setItem('hotelMode', hotel.hotelMode);
    sessionStorage.setItem('hotelGroupId', hotel.hotelGroupId);
    this.router.navigate(['/dashboard']);
  }
}
