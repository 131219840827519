import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../shared/http.service';
import {EventLogModel} from '../../models/event-log.model';
import {RandomListsService} from '../../shared/random-lists.service';
import * as moment from 'moment';
import {EmployeeModel} from '../../models/employee.model';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.css']
})
export class EventLogsComponent implements OnInit {
  eventDescription = ''; eventType = 'normal';
  modalRef;
  hotelId = localStorage.getItem('hotelId');
  employeeId = localStorage.getItem('employeeId');
  showAlert = false; alertType; alertMessage;
  eventLogs: EventLogModel[] = [];
  loading = true;
  employee: EmployeeModel = new EmployeeModel();

  constructor(private modalService: NgbModal,
              private httpService: HttpService,
              private randomList: RandomListsService) { }

  ngOnInit(): void {
    this.getEmployeeDetail();
  }

  getEmployeeDetail() {
    let path;
    if (this.hotelId) {
      path = `employees/${(this.employeeId)}?hotelId=${(this.hotelId)}`;
    } else {
      path = `employees/${(this.employeeId)}`;
    }

    this.httpService.getEmployeeDetails(path).subscribe(
      res => {
        this.employee = res;
        this.getInitialHotelEventLogs();
      },
      err => {
        console.log(err);
      }
    );
  }

  createNewEvent() {
    const newEvent = {
      hotelId: this.hotelId,
      employeeId: this.employeeId,
      eventType: this.eventType,
      description: this.eventDescription
    };

    this.httpService.createNewEvent('employees/newEventLog', newEvent).subscribe(
      () => {
        this.eventDescription = '';
        this.eventType = 'normal';
        this.showAlert = true;
        this.alertType = 'success';
        this.alertMessage = 'New event created!';
        this.modalRef.close();
        this.getInitialHotelEventLogs();
      },
      err => {
        console.log(err);
      }
    );
  }

  filterEvents($event) {
    const value = $event.target.value;
    let path;
    if (value !== 'all') {
      path = `hotels/${this.hotelId}/eventLogs?accessRole=${value}`;
    } else {
      path = `hotels/${this.hotelId}/eventLogs`;
    }
    this.getHotelEventLogs(path);
  }

  getInitialHotelEventLogs() {
    if (this.employee.accessRole === 'manager' || this.employee.accessRole === 'superadmin') {
      this.getHotelEventLogs();
    } else {
      this.getHotelEventLogs(`hotels/${this.hotelId}/eventLogs?accessRole=${this.employee.accessRole}`);
    }
  }

  getHotelEventLogs(path = `hotels/${this.hotelId}/eventLogs`) {
    this.httpService.getEventLogs(path).subscribe(
      res => {
        this.eventLogs = res;
        for (const log of this.eventLogs) {
          const dateArr = log.date.split(/[-T.:+]/);
          log.formattedDate = moment(`${dateArr[0]}${dateArr[1]}${dateArr[2]}`, 'YYYYMMDD').format('dddd, MMMM Do YYYY');
          for (const event of log.events) {
            event.formattedCreatedDateTime = this.randomList.formatDateTimeToTime(event.createdDateTime);
          }
        }
        this.loading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(() => {}, () => {});
  }

  onAlertClosed() {
    this.showAlert = false;
  }
}
