// @ts-ignore
import { INavData } from '@coreui/angular';

export const waiterFDNavItems: INavData[] = [
  /*{
    name: 'Dashboard',
    url: '/mis/house-cleaning/clean-room',
    icon: 'icon-grid',
  },*/
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  {
    name: 'MIS',
    url: '/mis',
    icon: 'icon-folder-alt',
    children: [
      {
        name: 'Staff Dashboard',
        url: '/mis/staff-duties/attendance-dashboard',
        icon: 'icon-layers'
      },
      {
        name: 'Staffs Roster',
        url: '/mis/staff-duties/staffs-roster',
        icon: 'icon-event'
      },
      {
        name: 'Duty Logs',
        url: '/mis/staff-duties/duty-logs',
        icon: 'icon-loop',
      },

      // {
      //   name: 'Rooms Inspection',
      //   url: '/mis/inspections/rooms-list',
      //   icon: 'icon-doc',
      // },

    ],
  },
];
