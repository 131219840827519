import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cs-page',
  templateUrl: './cs-page.component.html',
  styleUrls: ['./cs-page.component.css']
})
export class CsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
