import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RoomModel } from '../models/room.model';
import { HotelModel } from '../models/hotel.model';
import { ServiceModel } from '../models/service.model';
import { ReservationModel } from '../models/reservation.model';
import { GuestStayModel } from '../models/guest-stay.model';
import { GuestModel } from '../models/guest.model';
import { LaundryServiceModel } from '../models/laundry-service.model';
import { RoomServiceModel } from '../models/room-service.model';
import { IssueCommentModel } from '../models/issue-comment.model';
import { AccountDetailModel } from '../models/account-detail.model';
import { PosDetailModel } from '../models/pos-detail.model';
import { BalanceBillModel } from '../models/balance-bill.model';
import { AvailableRoomSummaryModel } from '../models/available-room-summary.model';
import { RoomTypeModel } from '../models/room-type.model';
import { BuildingModel } from '../models/building.model';
import { HouseKeepingModel } from '../models/housekeeping.model';
import { EmployeeModel } from '../models/employee.model';
import { RegisterModel } from '../models/register.model';
import { CashRegisterDetailsModel } from '../models/cashRegisterDetails';
import { CheckinOutModel } from '../models/checkin-out.model';
import { CheckedInRoomReportModel } from '../models/checkedIn-room-report.model';
import { RevenueReportModel } from '../models/revenue-report.model';
import { HotelStatsModel } from '../models/hotel-stats.model';
import { CustomerServiceStatsModel } from '../models/customer-service-stats.model';
import { ActivityCountModel } from '../models/activity-count.model';
import { BillingsModel } from '../models/billings.model';
import { BaseHttpService } from './base-http.service';
import { GuestsStatsModel } from '../models/guests-stats.model';
import { GuestReportModel } from '../models/guest-report.model';
import { ReceiptModel } from '../models/receipt.model';
import { PaymentTypeReportModel } from '../models/payment-type-report.model';
import { DeptCategoryModel } from '../models/dept-category.model';
import { DepartmentModel } from '../models/department.model';
import { TimeClockModel } from '../models/time-clock.model';
import { SurchargeModel } from '../models/surcharge.model';
import { AttendanceModel } from '../models/attendance.model';
import { EmployeePayModel } from '../models/employee-pay.model';
import { PayrollModel } from '../models/payroll.model';
import { GuestCheckinStat } from '../models/guest-checkin-stat';
import { GuestReservationStat } from '../models/guest-reservation-stat';
import { SpendingHabitReport } from '../models/spending-habit-report';
import { GuestPaymentStat } from '../models/guest-payment-stat';
import { RefundModel } from '../models/refund.model';
import { PettyCashModel } from '../models/petty-cash.model';
import { CashManagementModel } from '../models/cash-management.model';
import { ManagerCreditModel } from '../models/manager-credit.model';
import { DepositModel } from '../models/deposit.model';
import { RoomOutlookModel } from '../models/room-outlook.model';
import { AssetModel } from '../models/asset.model';
import { AssetMaintenanceModel } from '../models/asset-maintenance.model';
import { ConsumablesModel } from '../models/consumables.model';
import { ConsumableHistoryModel } from '../models/consumable-history.model';
import { MaintenanceModel } from '../models/maintenance.model';
import { CardkeyModel } from '../models/cardkey.model';
import { ReferralRevenueModel } from '../models/referral-revenue.model';
import { ReferralModel } from '../models/referral.model';
import { BreakfastModel } from '../models/breakfast.model';
import { BarPackageModel } from '../models/bar-package.model';
import { BfkMiniModel } from '../models/bfk-mini.model';
import { EventLogModel } from '../models/event-log.model';
import { RoomsInspectionModel } from '../models/rooms-inspection.model';
import { WeekendDayModel } from '../models/weekend-day.model';
import { RentableProperty } from '../models/rentable-property.model';
import { RoomCleaningModel } from '../models/room-cleaning.model';
import { EventModel } from '../models/event.model';
import { BillPaymentModel } from '../models/bill-payment.model';

import {LoginModel} from '../models/login.model';
import {AuthDeviceModel} from '../models/auth-device.model';
import { AmenitiesModel } from '../models/amenities.model';
import { LocalGovtsModel } from '../models/localGovts.model';
import { StateModel } from '../models/state.model';
import { CityModel } from '../models/city.model';
import { CustomerFeedBacksModel } from '../models/customer-feedBacks.model';
import { LedgerAccountModel } from '../models/ledger-account.model';
import { HotelRequestModel } from '../models/hotel-request.model';
import { BusinessCustomerModel } from '../models/business-customer.model';
import { GcReportModel } from '../models/gc-report.model';
import { MenuModel } from '../models/hotel-menu.model';
import { QualificationsModel } from '../models/qualifications.model';
import { EmployeeIntelligenceModel } from '../models/employee-intelligence.model';
import { DepartmentJobPositions } from '../models/department-job-positions.model';
import { TaxPaymentModel } from '../models/tax-payment.model';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private baseUrl = this.baseHttpService.baseUrl;
  private macAddressUrl = 'http://localhost:8001/getmac';

  constructor(
    private http: HttpClient,
    private baseHttpService: BaseHttpService
  ) {}

  getRooms(path: string) {
    return this.http.get<RoomModel[]>(`${this.baseUrl}/${path}`);
  }

  getRoomInfo(path: string) {
    return this.http.get<RoomModel>(`${this.baseUrl}/${path}`);
  }

  getHotelInfo(path: string) {
    return this.http.get<HotelModel>(`${this.baseUrl}/${path}`);
  }

  getHotels(path: string) {
    return this.http.get<HotelModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmployees(path: string) {
    return this.http.get<EmployeeModel[]>(`${this.baseUrl}/${path}`);
  }

  getCurrentCheckedInRooms(path: string) {
    return this.http.get<any[]>(`${this.baseUrl}/${path}`);
  }

  getReservations(path: string) {
    return this.http.get<ReservationModel[]>(`${this.baseUrl}/${path}`);
  }

  getCurrentGuests(path: string) {
    return this.http.get<GuestStayModel[]>(`${this.baseUrl}/${path}`);
  }

  getLaundryList(path: string) {
    return this.http.get<LaundryServiceModel[]>(`${this.baseUrl}/${path}`);
  }

  getHousekeepingList(path: string) {
    return this.http.get<HouseKeepingModel[]>(`${this.baseUrl}/${path}`);
  }

  getRoomServiceList(path: string) {
    return this.http.get<RoomServiceModel[]>(`${this.baseUrl}/${path}`);
  }

  getIssuesCommentsList(path: string) {
    return this.http.get<IssueCommentModel[]>(`${this.baseUrl}/${path}`);
  }

  getPhoneNumberList(path: string) {
    return this.http.get<GuestModel[]>(`${this.baseUrl}/${path}`);
  }

  getGuests(path: string) {
    return this.http.get<GuestModel>(`${this.baseUrl}/${path}`);
  }

  getGuestClub(path: string) {
    return this.http.get<GuestModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelAccountDetails(path: string) {
    return this.http.get<AccountDetailModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelPosDetails(path: string) {
    return this.http.get<PosDetailModel[]>(`${this.baseUrl}/${path}`);
  }

  getGuestBillBalance(path: string) {
    return this.http.get<BalanceBillModel>(`${this.baseUrl}/${path}`);
  }

  getAvailableRoomsSummary(path: string) {
    return this.http.get<AvailableRoomSummaryModel[]>(
      `${this.baseUrl}/${path}`
    );
  }

  getAllHotelRooms(path: string) {
    return this.http.get<RoomModel[]>(`${this.baseUrl}/${path}`);
  }

  getReservationInfo(path: string) {
    return this.http.get<ReservationModel>(`${this.baseUrl}/${path}`);
  }

  getHotelRoomTypes(path: string) {
    return this.http.get<RoomTypeModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelBuildings(path: string) {
    return this.http.get<BuildingModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelServices(path: string) {
    return this.http.get<ServiceModel[]>(`${this.baseUrl}/${path}`);
  }


  getHotelStaffs(path: string) {
    return this.http.get<EmployeeModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmailsList(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  getRoomNumbersList(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  getRoomTypesList(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  getNamesList(path: string) {
    return this.http.get<GuestModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmployeeDetails(path: string) {
    return this.http.get<EmployeeModel>(`${this.baseUrl}/${path}`);
  }

  getEmployeeClockInStatus(path: string) {
    return this.http.get<TimeClockModel>(`${this.baseUrl}/${path}`);
  }

  getOpenedCashRegister(path: string) {
    return this.http.get<RegisterModel>(`${this.baseUrl}/${path}`);
  }

  getCashRegisterSummary(path: string) {
    return this.http.get<CashRegisterDetailsModel[]>(`${this.baseUrl}/${path}`);
  }

  getCashRegisters(path: string) {
    return this.http.get<RegisterModel[]>(`${this.baseUrl}/${path}`);
  }

  getCheckInDetails(path: string) {
    return this.http.get<CheckinOutModel>(`${this.baseUrl}/${path}`);
  }

  getRoomStatusReport(path: string) {
    return this.http.get<RoomModel[]>(`${this.baseUrl}/${path}`);
  }

  processGenericGet(path: string) {
    return this.http.get<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  getCheckedInRoomsReport(path: string) {
    return this.http.get<CheckedInRoomReportModel[]>(`${this.baseUrl}/${path}`);
  }

  getCheckedInOutRoomsReport(path: string) {
    return this.http.get<CheckinOutModel[]>(`${this.baseUrl}/${path}`);
  }

  getActivityCount(path: string) {
    return this.http.get<ActivityCountModel>(`${this.baseUrl}/${path}`);
  }

  getRevenueReport(path: string) {
    return this.http.get<RevenueReportModel[]>(`${this.baseUrl}/${path}`);
  }
  getWebReport(path: string) {
    return this.http.get<ReservationModel[]>(`${this.baseUrl}/${path}`);
  }
  getBookReport(path: string) {
    return this.http.get<ReservationModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelStats(path: string) {
    return this.http.get<HotelStatsModel>(`${this.baseUrl}/${path}`);
  }

  getGuestsStats(path: string) {
    return this.http.get<GuestsStatsModel>(`${this.baseUrl}/${path}`);
  }

  getCustomerServiceStats(path: string) {
    return this.http.get<CustomerServiceStatsModel>(`${this.baseUrl}/${path}`);
  }

  getCashRegisterStats(path: string) {
    return this.http.get<RegisterModel>(`${this.baseUrl}/${path}`);
  }

  getRevenueStats(path: string) {
    return this.http.get<BillingsModel[]>(`${this.baseUrl}/${path}`);
  }

  getCustomersList(path: string) {
    return this.http.get<GuestReportModel[]>(`${this.baseUrl}/${path}`);
  }

  getPaymentTypeRevenue(path: string) {
    return this.http.get<PaymentTypeReportModel[]>(`${this.baseUrl}/${path}`);
  }

  getDepartmentCategories(path: string) {
    return this.http.get<DeptCategoryModel[]>(`${this.baseUrl}/${path}`);
  }

  getDepartments(path: string) {
    return this.http.get<DepartmentModel[]>(`${this.baseUrl}/${path}`);
  }

  getSurchargeReasons(path: string) {
    return this.http.get<SurchargeModel[]>(`${this.baseUrl}/${path}`);
  }

  getSurcharges(path: string) {
    return this.http.get<SurchargeModel[]>(`${this.baseUrl}/${path}`);
  }

  getOneSurchargeDetail(path: string) {
    return this.http.get<SurchargeModel>(`${this.baseUrl}/${path}`);
  }

  getAttendance(path: string) {
    return this.http.get<AttendanceModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmployeesToPay(path: string) {
    return this.http.get<EmployeePayModel[]>(`${this.baseUrl}/${path}`);
  }

  getPayrolls(path: string) {
    return this.http.get<PayrollModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmployeePayDetails(path: string) {
    return this.http.get<EmployeePayModel>(`${this.baseUrl}/${path}`);
  }

  getMonthlyAttendance(path: string) {
    return this.http.get<AttendanceModel[]>(`${this.baseUrl}/${path}`);
  }

  getGuestReport(path: string) {
    return this.http.get<GuestReportModel>(`${this.baseUrl}/${path}`);
  }

  getGuestCheckInStats(path: string) {
    return this.http.get<GuestCheckinStat>(`${this.baseUrl}/${path}`);
  }

  getGuestReservationStats(path: string) {
    return this.http.get<GuestReservationStat>(`${this.baseUrl}/${path}`);
  }

  getSpendingHabits(path: string) {
    return this.http.get<SpendingHabitReport[]>(`${this.baseUrl}/${path}`);
  }

  getGuestPaymentStats(path: string) {
    return this.http.get<GuestPaymentStat>(`${this.baseUrl}/${path}`);
  }

  getGuestDeposits(path: string) {
    return this.http.get<DepositModel[]>(`${this.baseUrl}/${path}`);
  }

  getMacAddress() {
    return this.http.get<{ mac: string }>(this.macAddressUrl);
  }

  getAssetMaintenanceHistory(path: string) {
    return this.http.get<AssetMaintenanceModel[]>(`${this.baseUrl}/${path}`);
  }

  getCardKey(path) {
    return this.http.get<CardkeyModel[]>(`${this.baseUrl}/${path}`);
  }

  selectedBankAccount(path: string) {
    return this.http.get<AccountDetailModel>(`${this.baseUrl}/${path}`);
  }

  selectedPos(path: string) {
    return this.http.get<PosDetailModel>(`${this.baseUrl}/${path}`);
  }

  getRefunds(path: string) {
    return this.http.get<RefundModel[]>(`${this.baseUrl}/${path}`);
  }

  getCashBalance(path: string) {
    return this.http.get<{ balance: number }>(`${this.baseUrl}/${path}`);
  }

  getPettyCashList(path: string) {
    return this.http.get<PettyCashModel[]>(`${this.baseUrl}/${path}`);
  }

  getCashManagementList(path: string) {
    return this.http.get<CashManagementModel[]>(`${this.baseUrl}/${path}`);
  }

  getGuestCreditLimit(path: string) {
    return this.http.get<GuestModel>(`${this.baseUrl}/${path}`);
  }

  getManagerCredits(path: string) {
    return this.http.get<BillingsModel[]>(`${this.baseUrl}/${path}`);
  }

  getManagerCreditList(path: string) {
    return this.http.get<ManagerCreditModel[]>(`${this.baseUrl}/${path}`);
  }

  getRoomOutlook(path: string) {
    return this.http.get<RoomOutlookModel[]>(`${this.baseUrl}/${path}`);
  }

  getAssets(path: string) {
    return this.http.get<AssetModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelConsumables(path: string) {
    return this.http.get<ConsumablesModel[]>(`${this.baseUrl}/${path}`);
  }

  getConsumablesHistory(path: string) {
    return this.http.get<ConsumableHistoryModel[]>(`${this.baseUrl}/${path}`);
  }

  getMaintenanceRecord(path: string) {
    return this.http.get<MaintenanceModel[]>(`${this.baseUrl}/${path}`);
  }

  getReferralRevenue(path: string) {
    return this.http.get<ReferralRevenueModel[]>(`${this.baseUrl}/${path}`);
  }

  createHotel(path: string, hotelData: FormData) {
    return this.http.post<{ message: string, success: string }>(`${this.baseUrl}/${path}`, hotelData);
  }

  createService(path: string, serviceDetails: ServiceModel) {
    return this.http.post(`${this.baseUrl}/${path}`, serviceDetails);
  }

  createRoom(path: string, roomDetails: FormData) {
    return this.http.post(`${this.baseUrl}/${path}`, roomDetails);
  }

  createHotelAmenity(path: string, roomDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, roomDetails);
  }

  createEmployee(path: string, employeeDetails: FormData) {
    return this.http.post<{success: string; message: string}>(`${this.baseUrl}/${path}`, employeeDetails);
  }

  createLaundryService(path: string, laundryDetails: LaundryServiceModel) {
    return this.http.post(`${this.baseUrl}/${path}`, laundryDetails);
  }

  createHouseKeepingRequest(
    path: string,
    houseKeepingDetails: HouseKeepingModel
  ) {
    return this.http.post(`${this.baseUrl}/${path}`, houseKeepingDetails);
  }

  createRoomService(path: string, serviceRequestDetails: RoomServiceModel) {
    return this.http.post<{ message: string, success: string }>(`${this.baseUrl}/${path}`, serviceRequestDetails);
  }

  createIssueComment(path: string, issueCommentDetails: IssueCommentModel) {
    return this.http.post(`${this.baseUrl}/${path}`, issueCommentDetails);
  }

  createRoomType(path: string, roomTypeDetails: FormData) {
    return this.http.post<{message: string, images, success: true}>(`${this.baseUrl}/${path}`, roomTypeDetails);
  }

  createRoomTypeImages(path: string, roomTypeDetails: FormData) {
    return this.http.post(`${this.baseUrl}/${path}`, roomTypeDetails);
  }

  createBuilding(path: string, buildingDetail: BuildingModel) {
    return this.http.post(`${this.baseUrl}/${path}`, buildingDetail);
  }

  createReceipt(path: string, receiptDetails) {
    return this.http.post<ReceiptModel>(
      `${this.baseUrl}/${path}`,
      receiptDetails
    );
  }

  createDeptCategory(path: string, deptCategoryDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, deptCategoryDetail);
  }

  createDepartment(path: string, departmentDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, departmentDetail);
  }

  createSurcharge(path: string, surchargeDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, surchargeDetail);
  }

  createPayroll(path: string, payrollDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, payrollDetail);
  }

  createNewGuest(path: string, guestDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, guestDetail);
  }

  timeClockLogin(path: string, timeClockPin) {
    return this.http.post<EmployeeModel>(
      `${this.baseUrl}/${path}`,
      timeClockPin
    );
  }

  clockIn(path: string, clockInDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, clockInDetails);
  }

  addHotelAccount(path: string, accountDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, accountDetails);
  }

  openCashRegister(path: string, registerDetail) {
    return this.http.post<RegisterModel>(
      `${this.baseUrl}/${path}`,
      registerDetail
    );
  }

  dropOffKey(path: string, keyDropOffDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, keyDropOffDetails);
  }

  lostRoomKey(path: string, roomDetails) {
    return this.http.post<{ message: string; token: string }>(`${this.baseUrl}/${path}`, roomDetails);
  }

  changeRoom(path: string, changeRoomDetails) {
    return this.http.post<{ message: string; token: string }>(`${this.baseUrl}/${path}`, changeRoomDetails);
  }

  changeKey(path: string, roomDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, roomDetails);
  }

  createEmployeePaymentDetail(path: string, paymentDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, paymentDetail);
  }

  validatePost(path: string, data) {
    return this.http.post(`${this.baseUrl}/${path}`, data);
  }

  insertPettyCash(path: string, pettyCashDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, pettyCashDetail);
  }

  insertCashManagement(path: string, cashManagementDetail) {
    return this.http.post(`${this.baseUrl}/${path}`, cashManagementDetail);
  }

  terminateEmployee(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  createAsset(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }
  createDashBoardReports(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  createMaintenance(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  createConsumableItem(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  maintenanceAction(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  handleMcCodeRequest(path: string, details) {
    return this.http.post<{ managerCode: string; maxUsagePercent }>(
      `${this.baseUrl}/${path}`,
      details
    );
  }
  handleClubCodeRequest(path: string, details) {
    return this.http.post<{
      success: Boolean;
      clubCode: string; percentDiscount }>(
      `${this.baseUrl}/${path}`,
      details
    );
  }

  helperPost(path: string, data) {
    return this.http.post<{
      token: any;
      success: any;
      message: string }>(`${this.baseUrl}/${path}`, data);
  }

  handleCheckInCode(path, data) {
    return this.http.post<{success: any; checkInCode: string; message: string }>(
      `${this.baseUrl}/${path}`,
      data
    );
  }

  helperPut(path: string, data) {
    return this.http.put<{ message: string, success: string }>(`${this.baseUrl}/${path}`, data);
  }

  handleClockInActions(path: string, actionDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, actionDetails);
  }

  editHotelInfo(path: string, hotelData: FormData) {
    return this.http.put(`${this.baseUrl}/${path}`, hotelData);
  }

  editRoomInfo(path: string, roomData: FormData) {
    return this.http.put(`${this.baseUrl}/${path}`, roomData);
  }

  editEmployeeInfo(path: string, employeeData: FormData) {
    return this.http.put(`${this.baseUrl}/${path}`, employeeData);
  }
  editEmployeeRoster(path: string, employeeData) {
    return this.http.put(`${this.baseUrl}/${path}`, employeeData);
  }

  editReservation(path: string, reservationDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, reservationDetail);
  }

  editBuilding(path: string, buildingDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, buildingDetails);
  }

  editService(path: string, serviceDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, serviceDetails);
  }

  editRoomType(path: string, roomTypeDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, roomTypeDetail);
  }

  editPaymentAccount(path: string, accountDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, accountDetail);
  }

  editSurcharge(path: string, surchargeDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, surchargeDetail);
  }

  editPayroll(path: string, payrollDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, payrollDetail);
  }

  updateConfirmationStatus(path: string, updateDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, updateDetail);
  }

  handlePayrollActions(path: string, actionDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, actionDetail);
  }

  changeTimeClockPin(path: string, employeeDetail) {
    return this.http.put<EmployeeModel>(
      `${this.baseUrl}/${path}`,
      employeeDetail
    );
  }

  pickUpKey(path: string, pickUpDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, pickUpDetail);
  }

  processRefund(path: string, refundDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, refundDetails);
  }

  clearCredit(path: string, creditDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, creditDetail);
  }

  checkInGuest(path: string, checkInDetails) {
    return this.http.post<{message: string, token: string}>(`${this.baseUrl}/${path}`, checkInDetails);
  }

  checkOutGuest(path: string, checkOutDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, checkOutDetails);
  }

  makeReservation(path: string, reservationDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, reservationDetails);
  }

  makeExtraPayment(path: string, paymentDetails) {
    return this.http.post<{message: string, token: string}>(`${this.baseUrl}/${path}`, paymentDetails);
  }

  cancelReservation(path: string, reservationDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, reservationDetails);
  }

  reservationCheckIn(path: string, reservationDetails) {
    return this.http.post<{message: string, token: string}>(`${this.baseUrl}/${path}`, reservationDetails);
  }

  extendStay(path: string, extendStayDetails) {
    return this.http.post<{message: string, token: string}>(`${this.baseUrl}/${path}`, extendStayDetails);
  }

  countUnprocessedLaundryRequests(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  updateLaundryRequest(path: string, laundryDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, laundryDetails);
  }
  updateHousekeepingRequest(path: string, housekeepingDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, housekeepingDetails);
  }

  countUnprocessedRoomServiceRequests(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  countUnprocessedHousekeepingRequest(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  updateRoomServiceRequest(path: string, roomServiceDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, roomServiceDetails);
  }

  countUnprocessedIssuesComments(path: string) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  updateIssuesComments(path: string, issuesCommentsDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, issuesCommentsDetails);
  }

  closeCashRegister(path: string, cashRegisterDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, cashRegisterDetails);
  }

  updateLoginAccess(path: string, statusDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, statusDetails);
  }

  updateLoginDetails(path: string, loginDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, loginDetails);
  }

  editGuestDetails(path: string, guestDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, guestDetail);
  }

  resetPassword(path: string, passwordDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, passwordDetails);
  }

  updateRoomSaleStatus(path: string, saleStatus) {
    return this.http.put(`${this.baseUrl}/${path}`, saleStatus);
  }

  updateDepartmentCategory(path: string, deptCategoryDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, deptCategoryDetail);
  }

  updateDepartment(path: string, departmentDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, departmentDetail);
  }

  editEmployeePayDetail(path: string, payDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, payDetail);
  }

  updateAsset(path: string, assetDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, assetDetail);
  }

  updateItemNumber(path: string, detail) {
    return this.http.put(`${this.baseUrl}/${path}`, detail);
  }

  deleteBuilding(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteHotelService(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteRoomType(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteAccount(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteRoom(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteSurcharge(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deletePayroll(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteDeptCategory(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  deleteDepartment(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  // Groups by a particular route

  getReferrals(path: string) {
    return this.http.get<ReferralModel[]>(`${this.baseUrl}/${path}`);
  }

  createReferral(path: string, referralDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, referralDetails);
  }

  updateReferral(path: string, referralDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, referralDetails);
  }

  deleteReferral(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  getBreakfasts(path: string) {
    return this.http.get<BreakfastModel[]>(`${this.baseUrl}/${path}`);
  }

  createBreakfast(path: string, breakfastDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, breakfastDetails);
  }

  editBreakfast(path: string, breakfastDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, breakfastDetails);
  }

  deleteBreakfast(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  getBarPackages(path: string) {
    return this.http.get<BarPackageModel[]>(`${this.baseUrl}/${path}`);
  }

  createBarPackage(path: string, barPackageDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, barPackageDetails);
  }

  editBarPackage(path: string, barPackageDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, barPackageDetails);
  }

  deleteBarPackage(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  getGuestsBreakfastBar(path: string) {
    return this.http.get<BfkMiniModel[]>(`${this.baseUrl}/${path}`);
  }

  createNewEvent(path: string, eventDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, eventDetails);
  }

  getEventLogs(path: string) {
    return this.http.get<EventLogModel[]>(`${this.baseUrl}/${path}`);
  }

  getInspectionQuestions(path: string) {
    return this.http.get<RoomsInspectionModel[]>(`${this.baseUrl}/${path}`);
  }

  createInspection(path: string, roomInspection) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      roomInspection
    );
  }

  updateRoomInspection(path: string, inspectionDetails) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      inspectionDetails
    );
  }

  addWeekendDay(path: string, weekendDay: WeekendDayModel) {
    return this.http.post(`${this.baseUrl}/${path}`, weekendDay);
  }

  getWeekendDays(path: string) {
    return this.http.get<WeekendDayModel[]>(`${this.baseUrl}/${path}`);
  }

  deleteWeekendDay(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  createRentalProperty(path: string, propertyDetail: RentableProperty) {
    return this.http.post(`${this.baseUrl}/${path}`, propertyDetail);
  }

  getRentalProperties(path: string) {
    return this.http.get<RentableProperty[]>(`${this.baseUrl}/${path}`);
  }

  updateRentalProperty(path: string, details) {
    return this.http.put(`${this.baseUrl}/${path}`, details);
  }

  deleteRentalProperty(path: string) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  getRoomCleanings(path: string) {
    return this.http.get<RoomCleaningModel[]>(`${this.baseUrl}/${path}`);
  }

  getEvents(path: string) {
    return this.http.get<EventModel[]>(`${this.baseUrl}/${path}`);
  }
  getEvent(path: string) {
    return this.http.get<EventModel>(`${this.baseUrl}/${path}`);
  }

  updateEvent(path: string, eventDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, eventDetails);
  }

  getBillPayments(path: string) {
    return this.http.get<BillPaymentModel[]>(`${this.baseUrl}/${path}`);
  }

  getLogAuditTrail(path: string) {
    return this.http.get<LoginModel[]>(`${this.baseUrl}/${path}`);
  }

  addDevice(path: string, deviceDetails) {
    return this.http.post<{phoneCode: string}>(`${this.baseUrl}/${path}`, deviceDetails);
  }

  getDevice(path: string) {
    return this.http.get<AuthDeviceModel[]>(`${this.baseUrl}/${path}`);
  }

  updateDevice(path: string, deviceDetails) {
    return this.http.put<{message: string}>(`${this.baseUrl}/${path}`, deviceDetails);
  }

  deActivateDevice(path) {
    return this.http.delete<{message: string}>(`${this.baseUrl}/${path}`);
  }

  getStates(path: string) {
    return this.http.get<StateModel[]>(`${this.baseUrl}/${path}`);
  }

  getLocalGovts(path: string) {
    return this.http.get<LocalGovtsModel[]>(`${this.baseUrl}/${path}`);
  }

  getCities(path: string) {
    return this.http.get<CityModel[]>(`${this.baseUrl}/${path}`);
  }

  createCities(path: string, cityDetails) {
    return this.http.post<{ message: string }>(`${this.baseUrl}/${path}`, cityDetails);
  }

  updateCities(path: string, cityDetails) {
    return this.http.put<{ message: string }>(`${this.baseUrl}/${path}`, cityDetails);
  }

  getHotelAmenities(path: string) {
    return this.http.get<AmenitiesModel[]>(`${this.baseUrl}/${path}`);
  }

  createHotelAmenities(path: string, amenityDetails) {
    return this.http.post<{message: string}>(`${this.baseUrl}/${path}`, amenityDetails);
  }

  editHotelAmenities(path: string, amenityDetails) {
    return this.http.put<{message: string}>(`${this.baseUrl}/${path}`, amenityDetails);
  }

  getAllCustomerRequests(path) {
    return this.http.get<CustomerFeedBacksModel[]>(`${this.baseUrl}/${path}`);
  }


  resolveCustomerRequests(path, data) {
    return this.http.put<{ message: string }>(`${this.baseUrl}/${path}`, data);
  }

  sendMessage(path, data) {
    return this.http.post<{ message: string }>(`${this.baseUrl}/${path}`, data);
  }

  getHotelLedger(path: string) {
    return this.http.get<LedgerAccountModel[]>(`${this.baseUrl}/${path}`);
  }

  getHotelRequests(path: string) {
    return this.http.get<HotelRequestModel[]>(`${this.baseUrl}/${path}`);
  }

  getBusinessCustomer(path: string){
    return this.http.get<BusinessCustomerModel[]>(`${this.baseUrl}/${path}`);
  }
createBusinessCustomer(path, data){
    return this.http.post<{message: string}>(`${this.baseUrl}/${path}`, data);
}

getGcReports(path: string){
    return this.http.get<GcReportModel[]>(`${this.baseUrl}/${path}`);
}

  getMenu(path: string){
    return this.http.get<MenuModel[]>(`${this.baseUrl}/${path}`);
  }

  getStatus(path) {
    return this.http.get<EmployeeIntelligenceModel>(`${this.baseUrl}/${path}`);
  }

  getQualification(path: string) {
    return this.http.get<QualificationsModel[]>(`${this.baseUrl}/${path}`);
  }

  editQualification(path: string, data) {
    return this.http.put<QualificationsModel>(`${this.baseUrl}/${path}`, data);
  }

  createQualification(path: string, data) {
    return this.http.post<QualificationsModel>(`${this.baseUrl}/${path}`, data);
  }

  getFromEms(path: string) {
    return this.http.get<EmployeeModel>(`${this.baseUrl}/${path}`);
  }


  getDepartmentPositions(path: string) {
    return this.http.get<DepartmentJobPositions[]>(`${this.baseUrl}/${path}`);
  }

  checkJobPosition(path: string) {
    return this.http.get<boolean>(`${this.baseUrl}/${path}`);
  }

  createE_commission(path: string, commissionDetails) {
    return this.http.post<{message: string}>(`${this.baseUrl}/${path}`, commissionDetails);
  }

  getTaxLevies(path: string) {
    return this.http.get<TaxPaymentModel[]>(`${this.baseUrl}/${path}`);
  }
}
