import { INavData } from '@coreui/angular';

export const frontDeskNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  {
    name: 'Rooms',
    url: '/room',
    icon: 'icon-home',
    children: [
      {
        name: 'Checkin',
        url: '/room/checkin',
        icon: 'icon-login',
      },
      {
        name: 'Checkout',
        url: '/room/checkout',
        icon: 'icon-logout',
      },
      {
        name: 'Make Reservation',
        url: '/room/make-reservation',
        icon: 'icon-plus',
      },
      {
        name: 'Reservations',
        url: '/room/reservations',
        icon: 'icon-close',
      },
    ],
  },
  {
    name: 'Guests',
    url: '/guests',
    icon: 'icon-people',
    children: [
      {
        name: 'Guests List',
        url: '/guests/guests-list',
        icon: 'icon-list',
      },
      {
        name: 'Customer Payment',
        url: '/guests/pay',
        icon: 'icon-credit-card',
      },
      {
        name: 'BFK/Mini',
        url: '/guests/bfkmini',
        icon: 'icon-cup',
      },
    ],
  },
  {
    name: 'Cash Register',
    url: '/cash-register',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Manage Cash Register',
        url: '/cash-register/manage-cash-register',
        icon: 'icon-book-open',
      },
      {
        name: 'Cash Register History',
        url: '/cash-register/cash-register-audit',
        icon: 'icon-calculator',
      },
    ],
  },
  {
    name: 'Customer Service',
    url: '/customer-service',
    icon: 'icon-cup',
    children: [
      {
        name: 'Laundry Service',
        url: '/customer-service/laundry-service',
        icon: 'icon-layers',
      },
      {
        name: 'House Keeping',
        url: '/customer-service/housekeeping',
        icon: 'icon-loop',
      },
      {
        name: 'Room Service',
        url: '/customer-service/room-service',
        icon: 'icon-drawer',
      },
      {
        name: 'Issues/Comments',
        url: '/customer-service/issues-comments',
        icon: 'icon-volume-1',
      },
    ],
  },
  {
    name: 'BFK/Mini',
    url: '/guests/bfkmini',
    icon: 'icon-cup'
  },
  {
    name: 'Duty Logs',
    url: '/mis/staff-duties/duty-logs',
    icon: 'icon-loop',
  },
  /*{
  name: 'Room Cleaning',
  url: '/mis/house-cleaning/clean-room',
  icon: 'icon-refresh',
  },*/
  /*{
    name: 'Rooms Status',
    url: '/reports/rooms-report/room-status',
    icon: 'icon-diamond'
  }*/
];
