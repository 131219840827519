import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import {PropertyDisplayComponent} from './views/property-display/property-display.component';
import {AuthGuard} from './shared/auth.guard';
import {EventLogsComponent} from './views/event-logs/event-logs.component';
import { HotelMenuComponent } from './views/hotel-menu/hotel-menu.component';
import { ProjectsPageComponent } from './views/projects-page/projects-page.component';
import { CsPageComponent } from './views/cs-page/cs-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'projects',
    component: ProjectsPageComponent,
    data: {
      title: 'Project Page'
    }
  },
  {
    path: 'cs',
    component: CsPageComponent,
    data: {
      title: 'cs Page'
    }
  },
  {
    path: 'hotel-menu/:hotelGroupId/:hotelId/:menus-template-id/:menuId/:locationId',
    component: HotelMenuComponent,
    data: {
      title: 'Hotel-Menu Page'
    }
  },
  {
    path: 'hotel-menu/:menuLocationCode',
    component: HotelMenuComponent,
    data: {
      title: 'Hotel-Menu Page'
    }
  },
  {
    path: 'hotels',
    component: PropertyDisplayComponent,
    data: {
      title: 'Properties'
    }
  },
  {
    path: 'timeclock',
    loadChildren: () => import('./views/timeclock/timeclock.module').then(m => m.TimeclockModule)
  },
  {
    path: 'manager-credit',
    loadChildren: () => import('./views/manager-credit/manager-credit.module').then(m => m.ManagerCreditModule)
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'event-logs',
        component: EventLogsComponent,
        data: {
          title: 'Event Logs'
        }
      },
      {
        path: 'room',
        loadChildren: () => import('./views/room/room.module').then(m => m.RoomModule)
      },
      {
        path: 'guests',
        loadChildren: () => import('./views/guests/guests.module').then(m => m.GuestsModule)
      },
      {
        path: 'cash-register',
        loadChildren: () => import('./views/cash-register/cash-register.module').then(m => m.CashRegisterModule)
      },
      {
        path: 'customer-service',
        loadChildren: () => import('./views/customer-service/customer-service.module').then(m => m.CustomerServiceModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard-reports',
        loadChildren: () => import('./views/dashboard-reports/dashboard-reports.module').then(m => m.DashboardReportsModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin-duties/admin-duties.module').then(m => m.AdminDutiesModule)
      },
      {
        path: 'mis',
        loadChildren: () => import('./views/forms-inspection-system/forms-inspection-system.module').then(
          m => m.FormsInspectionSystemModule)
      }
    ]
  },
  {
    path: '**',
    component: P404Component
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
