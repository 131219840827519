import { INavData } from '@coreui/angular';

export const accountantNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid'
  },
  {
    name: 'Guests',
    url: '/guests',
    icon: 'icon-people',
    children: [
      {
        name: 'Guests List',
        url: '/guests/guests-list',
        icon: 'icon-list'
      },
      {
        name: 'Customer Payment',
        url: '/guests/pay',
        icon: 'icon-credit-card'
      },
      {
        name: 'BFK/Mini',
        url: '/guests/bfkmini',
        icon: 'icon-cup'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'icon-chart',
    children: [
      {
        name: 'Overview',
        url: '/reports/overview',
        icon: 'icon-bag'
      },
      {
        name: 'Graphical Overview',
        url: '/reports/graphical-overview',
        icon: 'icon-graph'
      },
      {
        name: 'Rooms Report',
        url: '/reports/rooms-report',
        icon: 'icon-directions'
      },
      {
        name: 'Revenues Report',
        url: '/reports/revenues-report',
        icon: 'icon-wallet'
      },
      {
        name: 'Guests Report',
        url: '/reports/guests-report',
        icon: 'icon-emotsmile'
      },
      {
        name: 'Management Report',
        url: '/reports/mgt-reports',
        icon: 'icon-organization'
      }
    ]
  },
  {
    name: 'Employees',
    icon: 'icon-settings',
    children: [
      {
        name: 'Create Employee',
        url: '/admin/employees/create',
        icon: 'icon-user-follow'
      },
      {
        name: 'Cash Register Audit',
        url: '/cash-register/cash-register-audit',
        icon: 'icon-calculator'
      },
      {
        name: 'Surcharges',
        url: '/admin/employees/surcharges',
        icon: 'icon-pin'
      },
      /*{
        name: 'Employees Control',
        url: '/admin/employees/control',
        icon: 'icon-equalizer'
      }*/
    ]
  },
  {
    name: 'Hotels',
    icon: 'icon-settings',
    children: [
      {
        name: 'Hotel Maintenance',
        url: '/admin/hotels/assets',
        icon: 'icon-folder-alt'
      }
    ]
  },
  {
    title: true,
    name: 'Accounting'
  },
  {
    name: 'Account',
    icon: 'icon-settings',
    children: [
      /*{
        name: 'Create/Edit Guest',
        url: '/admin/accounting/editcreate',
        icon: 'icon-user-following'
      },*/
      {
        name: 'Guests Refund',
        url: '/admin/accounting/refunds',
        icon: 'icon-action-redo'
      },
      {
        name: 'View Manager Credit',
        url: '/admin/accounting/view-credit',
        icon: 'icon-size-fullscreen'
      },
      {
        name: 'Petty Cash',
        url: '/admin/accounting/petty-cash',
        icon: 'icon-present'
      },
      {
        name: 'Petty Cash History',
        url: '/admin/accounting/pc-history',
        icon: 'icon-list'
      },
      {
        name: 'Cash Management',
        url: '/admin/accounting/cash-management',
        icon: 'icon-doc'
      },
      {
        name: 'CM History',
        url: '/admin/accounting/cm-history',
        icon: 'icon-docs'
      },
      {
        name: 'Bill Payment',
        url: '/admin/accounting/bill-payment',
        icon: 'icon-calculator'
      },
      {
        name: 'Card Key Records',
        url: '/admin/accounting/cardkey-summary',
        icon: 'icon-book-open'
      }
    ]
  },
  {
    name: 'HR',
    icon: 'icon-settings',
    children: [
      {
        name: 'View Attendance',
        url: '/admin/hr/attendance',
        icon: 'icon-event'
      },
      {
        name: 'Monthly Attendance',
        url: '/admin/hr/monthly-attendance',
        icon: 'icon-layers'
      },
      {
        name: 'View Payroll',
        url: '/admin/hr/payrolls',
        icon: 'icon-film'
      }
    ]
  }
];
