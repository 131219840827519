import { INavData } from '@coreui/angular';

export const customerServiceNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  {
    name: 'Rooms',
    url: '/room',
    icon: 'icon-home',
    children: [
      {
        name: 'Checkin',
        url: '/room/checkin',
        icon: 'icon-login',
      },
      {
        name: 'Checkout',
        url: '/room/checkout',
        icon: 'icon-logout',
      },
      {
        name: 'Make Reservation',
        url: '/room/make-reservation',
        icon: 'icon-plus',
      },
      {
        name: 'Reservations',
        url: '/room/reservations',
        icon: 'icon-close',
      },
    ],
  },
  {
    title: true,
    name: 'Admin',
  },
  {
    name: 'Hotels',
    icon: 'icon-settings',
    children: [
      {
        name: 'Create Hotel',
        url: '/admin/hotels/create',
        icon: 'icon-plus'
      },
      {
        name: 'View Hotels',
        url: '/admin/hotels/list',
        icon: 'icon-bag'
      },
      {
        name: 'Hotel Assets',
        url: '/admin/hotels/assets',
        icon: 'icon-folder-alt'
      }
    ]
  },
  {
    name: 'Employees',
    icon: 'icon-settings',
    children: [
      // {
      //   name: 'Employees Dashboard',
      //   url: '/admin/employees/hotels',
      //   icon: 'icon-screen-desktop'
      // },
      {
        name: 'Create Employee',
        url: '/admin/employees/create',
        icon: 'icon-user-follow'
      },
      /*{
        name: 'Surcharges',
        url: '/admin/employees/surcharges',
        icon: 'icon-pin'
      },
      {
        name: 'Employees Control',
        url: '/admin/employees/control',
        icon: 'icon-equalizer'
      }*/
    ]
  },
  {
    name: 'System Table',
    icon: 'icon-docs',
    children: [
      {
        name: 'City/state Settings',
        url: '/admin/system-screens/city-settings',
        icon: 'icon-settings'
      },
      {
        name: 'Business Customers',
        url: '/admin/system-screens/business-customers',
        icon: 'icon-arrow-up-circle'
      }
    ]
  },
  {
    name: 'Customer Service',
    icon: 'icon-screen-desktop',
    children: [
      {
        name: 'Summary DB',
        url: '/admin/customer-service/cs-dashboard-summary',
        icon: 'icon-map'
      },
      /*{
        name: 'Reservations List',
        url: '/admin/customer-service/reservations-list',
        icon: 'icon-list'
      },
      {
        name: 'Subscriptions List',
        url: '/admin/customer-service/subscriptions-list',
        icon: 'icon-film'
      },*/
      {
        name: 'App Subscribers',
        url: '/admin/customer-service/app-subscribers',
        icon: 'icon-feed'
      },
      {
        name: 'App Reservations',
        url: '/admin/customer-service/app-reservations',
        icon: 'icon-briefcase'
      },
      {
        name: 'FeedBacks',
        url: '/admin/customer-service/customers-feedback',
        icon: 'icon-call-in'
      },
      {
        name: 'Hotels List',
        url: '/admin/customer-service/list-hotels',
        icon: 'icon-bulb'
      },
      {
        name: 'Requests List',
        url: '/admin/customer-service/requests-list',
        icon: 'icon-folder-alt'
      }
    ]
  },
  {
    name: 'MIS',
    url: '/mis',
    icon: 'icon-folder-alt',
    children: [
      {
        name: 'Staff Dashboard',
        url: '/mis/staff-duties/attendance-dashboard',
        icon: 'icon-layers'
      },
      {
        name: 'Duty Logs',
        url: '/mis/staff-duties/duty-logs',
        icon: 'icon-loop'
      },
      {
        name: 'Staffs Roster',
        url: '/mis/staff-duties/staffs-roster',
        icon: 'icon-event'
      },
      {
        name: 'Rooms Inspection',
        url: '/mis/inspections/rooms-list',
        icon: 'icon-doc'
      },
      {
        name: 'Room Cleaning',
        url: '/mis/house-cleaning/clean-room',
        icon: 'icon-refresh'
      },
      {
        name: 'Event Lists',
        url: '/mis/event-lists',
        icon: 'icon-event'
      }
    ]
  },
  /*{
    name: 'HR',
    icon: 'icon-settings',
    children: [
      {
        name: 'View Attendance',
        url: '/admin/hr/attendance',
        icon: 'icon-event'
      },
      {
        name: 'Monthly Attendance',
        url: '/admin/hr/monthly-attendance',
        icon: 'icon-layers'
      }
    ]
  }*/
];
