import {HotelModel} from './hotel.model';
import {SurchargeModel} from './surcharge.model';

export class EmployeeModel {
  employeeId: string;
  employeeName: string;
  gender: string;
  firstName: string;
  lastName: string;
  otherName: string;
  emailAddress: string;
  phoneNumber: number;
  operateRegister: string;
  totalRevenue: number;
  numOfHotels: number;
  primaryHotel: number;
  employeeHotels: HotelModel[];
  surcharges: SurchargeModel[];
  address: string;
  picture: string;
  statuss: string;
  loginStatus: string;
  accessRole: string;
  department: string;
  departmentName: string;
  timeClockPin: number;
  businessType: string;
  hotelName: string;
  hasPaymentDetails: boolean;
  clockInStatus: string;
  recurringClockIn: string | boolean;
  recurringClockInFrequency: number;
  outpostName: string;
  staffDetails: EmployeeModel[];
  numOfDutyStaff: EmployeeModel;
  clockInDateTime: string;
  clockOutDateTime: string;
  confirmationDateTime: void | string;
  wordClockOutDateTime: void | string;
  wordClockInDateTime: void | string;
  day: string;
  night: string;
  allDay: string;
  hoursDiff: number | string;
  shiftType: string;
  num: number;
  requiredStaffsNum: string;
  requiredStaffsDay: string;
  requiredStaffsNight: string;
  updatedById: string;
  createdDateTime: string;
  guestId: any;
  idFrontView: string;
  idBackView: string;
  active: string;
  idStatus: string;
  canCheckIn: string;
  hotelMode: string;
  sourceType: any;
  message: any;
  subject: any;
  phone: any;
  email: any;
  stateOfResidence: any;
  dateOfBirth: any;
  townOfResidence: any;
  qualification: any;
  maritalStatus: any;
  emsId: any;
  imageUrl: string;
  userId: string;
  occupation: any;
  age: any;
  employeesSkill: any;
  phones: any;
  guarantors: any;
  addressType: any;
  monthsOfExperience: any;
  code: any;
  userCommission: string;
  iamEmployee: string;
  saoEmployee: string;
  hotelRevenue: any;
  saoUserCommissionRate: any;
  ipmsCommissionRate: any;
  ipmsDueAmount: number;
  soaDueAmount: number;
}
