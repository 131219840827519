import { INavData } from '@coreui/angular';

export const marketerNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  {
    name: 'Make Reservation',
    url: '/room/make-reservation',
    icon: 'icon-plus'
  },
  {
    name: 'Reservations',
    url: '/room/reservations',
    icon: 'icon-close'
  },
  {
    name: 'Cash Register',
    url: '/cash-register',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Manage Cash Register',
        url: '/cash-register/manage-cash-register',
        icon: 'icon-book-open'
      },
      {
        name: 'Cash Register Audit',
        url: '/cash-register/cash-register-audit',
        icon: 'icon-calculator'
      }
    ]
  },
  {
    name: 'Referral Report',
    url: '/reports/revenues-report/referrals',
    icon: 'icon-bag'
  },
  {
    name: 'Reserved Rooms',
    url: '/reports/mgt-reports',
    icon: 'icon-organization'
  }
];
