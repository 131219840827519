import { INavData } from '@coreui/angular';

export const cardKeyAdminNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid'
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'icon-chart',
    children: [
      {
        name: 'Rooms Report',
        url: '/reports/rooms-report',
        icon: 'icon-directions'
      },
      {
        name: 'Management Report',
        url: '/reports/mgt-reports',
        icon: 'icon-organization'
      }
    ]
  }
];
