import { INavData } from '@coreui/angular';

export const hodOthersNavItems: INavData[] = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-grid',
    },
    {
      name: 'Dashboard-Reports',
      url: '/dashboard-reports',
      icon: 'icon-chart'
    },
  ];
