import { INavData } from '@coreui/angular';

export const housekeeperNavItems: INavData[] = [
  /*{
    name: 'Dashboard',
    url: '/mis/house-cleaning/clean-room',
    icon: 'icon-grid',
  },*/
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  /*{
    name: 'Customer Service',
    url: '/customer-service',
    icon: 'icon-cup',
    children: [
      {
        name: 'Laundry Service',
        url: '/customer-service/laundry-service',
        icon: 'icon-layers',
      },
      {
        name: 'House Keeping',
        url: '/customer-service/housekeeping',
        icon: 'icon-loop',
      },
      {
        name: 'Room Service',
        url: '/customer-service/room-service',
        icon: 'icon-drawer',
      },
      {
        name: 'Issues/Comments',
        url: '/customer-service/issues-comments',
        icon: 'icon-volume-1',
      },
    ],
  },*/
  /*{
    name: 'Guests',
    url: '/guests',
    icon: 'icon-people',
    children: [
      {
        name: 'BFK/Mini',
        url: '/guests/bfkmini',
        icon: 'icon-cup'
      }
    ]
  },*/
  {
    name: 'Dashboard-Reports',
    url: '/dashboard-reports',
    icon: 'icon-chart'
  },
  {
    name: 'MIS',
    url: '/mis',
    icon: 'icon-folder-alt',
    children: [
      {
        name: 'Room Cleaning',
        url: '/mis/house-cleaning/clean-room',
        icon: 'icon-refresh',
      },
      {
        name: 'Duty Logs',
        url: '/mis/staff-duties/duty-logs',
        icon: 'icon-loop',
      },
      {
        name: 'Staff Dashboard',
        url: '/mis/staff-duties/attendance-dashboard',
        icon: 'icon-layers'
      },
      {
        name: 'Staffs Roster',
        url: '/mis/staff-duties/staffs-roster',
        icon: 'icon-event'
      },
      // {
      //   name: 'Rooms Inspection',
      //   url: '/mis/inspections/rooms-list',
      //   icon: 'icon-doc',
      // },

    ],
  },
  {
    name: 'Maintenance',
    icon: 'icon-settings',
    children: [
      {
        name: 'Hotel Maintenance',
        url: '/admin/hotels/assets',
        icon: 'icon-folder-alt'
      }
    ]
  },
];
