import { INavData } from '@coreui/angular';

export const adminNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-grid',
  },
  {
    title: true,
    name: 'Admin',
  },
  {
    name: 'Hotels',
    icon: 'icon-settings',
    children: [
      {
        name: 'Create Hotel',
        url: '/admin/hotels/create',
        icon: 'icon-plus'
      },
      {
        name: 'View Hotels',
        url: '/admin/hotels/list',
        icon: 'icon-bag'
      },
      {
        name: 'Hotel Maintenance',
        url: '/admin/hotels/assets',
        icon: 'icon-folder-alt'
      }
    ]
  },
  {
    name: 'Dashboard-Reports',
    url: '/dashboard-reports',
    icon: 'icon-chart'
  },
  {
    name: 'Employees',
    icon: 'icon-settings',
    children: [
      {
        name: 'Employees Dashboard',
        url: '/admin/employees/hotels',
        icon: 'icon-screen-desktop'
      },
      {
        name: 'Create Employee',
        url: '/admin/employees/create',
        icon: 'icon-user-follow'
      },
      {
        name: 'Surcharges',
        url: '/admin/employees/surcharges',
        icon: 'icon-pin'
      },
      {
        name: 'Employees Control',
        url: '/admin/employees/control',
        icon: 'icon-equalizer'
      }
    ]
  },
  {
    name: 'HR',
    icon: 'icon-settings',
    children: [
      {
        name: 'View Attendance',
        url: '/admin/hr/attendance',
        icon: 'icon-event'
      },
      {
        name: 'Monthly Attendance',
        url: '/admin/hr/monthly-attendance',
        icon: 'icon-layers'
      }
    ]
  }
];
