import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class BaseHttpService {
  constructor() {}
  // https://ipms.intellims.com/
  public baseUrl = 'https://ipms.intellims.com:5300/api/v1';
  // public baseUrl = 'http://197.210.166.58:3000/api/v1';
  // public baseUrl = 'http://10.0.5.51:3012/api/v1';
  // public baseUrl = 'http://localhost:5100/api/v1';
}








