import { ServiceModel } from './service.model';
import { AccountDetailModel } from './account-detail.model';
import { RoomTypeStatsModel } from './room-type-stats.model';
import { DepartmentModel } from './department.model';

export class HotelModel {
  hotelId?: number | string;
  hotelName: string;
  numOfRooms: number;
  address: string;
  hotel: number | string;
  businessType: string;
  location: string;
  picture: string;
  accessRole: string;
  totalRoomsCreated: number;
  totalRoomsOccupied: number;
  managerName: string;
  totalEmployees: number;
  numOfStaffClockedIn: number;
  totalOccupancyRate: number;
  totalAvailableRooms: number;
  totalOccupiedRooms: number;
  useWeekendRate: string | boolean;
  useWebDiscountRate: string | boolean;
  roomTypesStats: RoomTypeStatsModel[];
  employeeStats: DepartmentModel[];
  services?: ServiceModel[];
  bankAccountDetails: AccountDetailModel[];
  webDiscount: any;
  discountPercent: any;
  hotelDescription: string;
  shortHotelDescription: string;
  state: any;
  stateId: any;
  lgaId: any;
  hotelCity: any;
  imageUrl: any;
  createdDateTime: string;
  showInBookSite: string | boolean;
  showInTiptop: string | boolean;
  showInCompanySite: string | boolean;
  hotelGroupName: any;
  hotelMode: string;
  hotelPhoneNumber: any;
  emailAddress: any;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  phoneNumber: string;
  employeeName: string;
  success: any;
  tiptopGoCommission: string;
  iPMSCommission: string;
  allowRooms: any;
  allowPayment: any;
  allowPayAtHotel: any;
  allowPayNow: any;
  iPMSFee: number;
  appPayAtHotel: any;
  appPayNow: any;
  tiptopGoVisibility: any;
  hotelGroupId: string;
  propertyType: string;
  hotelStar: string | number;
  hotelActiveCommission: any;
  marketerEmployees: any;
  ipmsCommissionRate: any;
  lattitude: any;
  longitude: any;
  hasSetupFB: any;
}
